<template>
  <Layout :tituloPagina="'Inicio Hotspot'">
    <div class="row" v-show="!$store.state.todo.susargus">
      <div class="col-lg-12">
        <div class="card bg-warning text-white">
          <div class="card-body text-center">
            <p class="card-text">
              Su suscripción ha caducado, debe de pagar su mensualidad para seguir disfrutando de ArgusBlack, 
              visite la sección <a href="/sistema/cuenta/" style="color: red;">CUENTA</a> para renovarla en este momento.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-3 col-lg-3 col-sm-6" v-if="tienePermiso('cmpSesionesActivasHotspot','componente')">
        <WFichas></WFichas>
      </div>
      <div class="col-xxl-3 col-lg-3 col-sm-6" v-if="tienePermiso('cmpFichasCreadasHotspot','componente')">
        <WHotspots></WHotspots>
      </div>
      <div class="col-xxl-3 col-lg-3 col-sm-6" v-if="tienePermiso('cmpFichasVendidasHotspot','componente')">
        <WLayouts></WLayouts>
      </div>
      <div class="col-xxl-3 col-lg-3 col-sm-6" v-if="tienePermiso('cmpFichasEliminadasHotspot','componente')">
        <WVendedores></WVendedores>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-6">
        <WVentasYComisiones></WVentasYComisiones>
      </div>
      <div class="col-xxl-6" v-if="tienePermiso('cmpIngresosHotspot','componente')">
        <WIngresos></WIngresos>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"

import WHotspots from './WHotspots.vue'
import WFichas from './WFichas.vue'
import WLayouts from './WLayouts.vue'
import WVendedores from './WVendedores.vue'
import WIngresos from './WIngresos.vue'
import WVentasYComisiones from "./WVentasYComisiones.vue"

import { todoGetters } from "@/state/helpers";

export default {
  name: 'Inicio',
  components: {
    Layout,
    WHotspots,
    WFichas,
    WLayouts,
    WVendedores,
    WIngresos,
    WVentasYComisiones
  },
  computed: { ...todoGetters },
}
</script>

<style scoped>

</style>