<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      <span class="badge bg-info rounded-pill bx-pull-right font-size-12">
        Estados
      </span>
      Hotspots
    </h5>
    <div class="card-body">
      <div class="row">
        <div class="col-6 text-center">
          <router-link :to="{ name: 'hotspots' }">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-success bg-gradient font-size-20" :title="cantidad_hotspot_activos">
                {{ cantidad_hotspot_activos <= 9999 ? cantidad_hotspot_activos : '999+' }}
              </div>
            </div>
            <div class="text-success text-center text-truncate w-100">
              En linea
            </div>
          </router-link>
        </div>

        <div class="col-6 text-center">
          <router-link :to="{ name: 'hotspots' }">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-danger bg-gradient font-size-20" :title="cantidad_hotspot_inactivos">
                {{ cantidad_hotspot_inactivos <= 9999 ? cantidad_hotspot_inactivos : '999+' }}
              </div>
            </div>
            <div class="text-danger text-center text-truncate w-100">
              Desconectados
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HotspotsSrv from '@/services/hotspot/HotspotsSrv.js'
import axios from 'axios'

export default {
  name: 'WHotspots',

  data() {
    return {
      cantidad_hotspot_activos: 0,
      cantidad_hotspot_inactivos: 0,
      intervals: {
        actualizacion: null
      }
    }
  },

  created() {
    var self = this

    this.actualizar()
    this.intervals.actualizacion = setInterval(function() {
      self.actualizar()
    }, 60000)
  },

  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },

  methods: {
    actualizar() {
      var self = this

      const prom1 = HotspotsSrv.cantidadActivos()
      const prom2 = HotspotsSrv.cantidadInactivos()

      axios.all([prom1, prom2]).then(response => {
        self.cantidad_hotspot_activos = response[0].data
        self.cantidad_hotspot_inactivos = response[1].data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo obtener la cantidad de sesiones activas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
}
</script>