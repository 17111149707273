<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      <span class="badge bg-primary rounded-pill bx-pull-right font-size-12">
        Cantidades
      </span>
      Vendedores
    </h5>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 text-center">
          <router-link :to="{ name: 'vendedoresHotspot' }">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-info bg-gradient font-size-20" :title="cantidad_vendedores">
                {{ cantidad_vendedores <= 9999 ? cantidad_vendedores : '999+' }}
              </div>
            </div>
            <div class="text-info text-center text-truncate w-100">
              Vendedores
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VendedorSrv from '@/services/hotspot/VendedorSrv'
export default {
  name: 'WFichasEliminadas',

  data() {
    return {
      cantidad_vendedores: 0,
      intervals: {
        actualizacion: null
      }
    }
  },
  created() {
    var self = this

    this.actualizar()
    this.intervals.actualizacion = setInterval(function() {
      self.actualizar()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    actualizar() {
      var self = this

      VendedorSrv.cantidad().then(response => {
        self.cantidad_vendedores = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo obtener la cantidad de fichas eliminadas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>