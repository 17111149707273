import axios from 'axios'
import { APIHOTS as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/fichas`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  cantidadCreadas() {
    return apiClient.get('/cantidad-creadas')
  },
  
  cantidadPausadas() {
    return apiClient.get('/cantidad-pausadas')
  },

  cantidadVendidas() {
    return apiClient.get('/cantidad-vendidas')
  },

  cantidadEliminadas() {
    return apiClient.get('/cantidad-eliminadas')
  },

  ventasJSON(params){
    params = params || {}
    return apiClient.get('/ventas.json', {params : params})
  },

  eliminarFICHA(idFicha) {
    return apiClient.delete(`/${idFicha}`)
  },

  eliminarFICHAS(params) {
    params = params || {}
    return apiClient.delete('',{params : params})
  },

  generar(bloques) {
    return apiClient.post('generar', bloques)
  },

  fichaJSON(idFicha,params) {
    params = params || {}
    return apiClient.get(`${idFicha}.json`,{params:params})
  },

  fichasJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/fichas.json`, { params: params })
  },

  fichasImprimirJSON(params) {
    params = params || {}
    return apiClient.get(`imprimir-fichas`, { params: params })
  },

  finalizarFICHA(idFicha) {
    return apiClient.patch(`/${idFicha}/finalizar`)
  },
}