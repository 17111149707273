<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      Ventas y Comisiones del usuario actual
    </h5>
    <div class="card-body">
      <div class="row">
        <div class="col-md-5">
          <div class="row mb-4">
            <label class="col-sm-4 col-4 col-form-label text-truncate">Fecha inicial</label>
            <div class="col-sm-8 col-8">
              <Datepicker
                ref="fecha_inicial"
                v-model="fecha_inicial"
                :class="'form-control bg-white'"
                :inputFormat="'dd MMMM yyyy'"
              />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="row mb-4">
            <label class="col-sm-4 col-4 col-form-label text-truncate">Fecha final</label>
            <div class="col-sm-8 col-8">
              <Datepicker
                ref="fecha_final"
                v-model="fecha_final"
                :class="'form-control bg-white'"
                :inputFormat="'dd MMMM yyyy'"
              />
            </div>
          </div>
        </div>
        <div class="col-md-2 col-5 text-right">
          <button class="btn btn-outline-success btn-block text-truncate w-100" @click="refrescarVentasComisiones()">
            <i class="mdi mdi-refresh"></i>
            Refrescar
          </button>
        </div>
      </div>
      <br>
      <p class="text-muted mb-1 text-justify">
        Esta gráfica te ayuda a ver las ventas brutas y netas y su comisión de las fichas de hotspot a lo largo del tiempo,
        se han vendido <strong>{{ Intl.NumberFormat('es-MX').format( ventas_y_comisiones.cantidad_fichas_vendidas ) }} fichas</strong>.
      </p>
      <br>
      <div id="graficaVentasComisiones">
        <apexchart
          class="apex-charts"
          height="450"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
<script>
import VendedorSrv from '@/services/hotspot/VendedorSrv.js'
import Datepicker from 'vue3-datepicker'
import accounting from 'accounting/accounting.js'
import moment from 'moment'

export default {
  name: 'WVentasYComisiones',
  components: {
    Datepicker
  },
  data() {
    return {
      fecha_inicial : '',
      fecha_final : '',

      ventas_y_comisiones: {
        cantidad_fichas_vendidas: 0,
        comisiones: 0,
        ventas_brutas: 0,
        ventas_netas: 0
      },

      series: [{
        data: []
      }],
      
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          }
        },
        colors: [],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: [],
              fontSize: "12px"
            }
          }
        }
      }
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created() {
    var self = this

    let hoy = new Date()
    var anio = hoy.getFullYear();
    self.fecha_inicial = new Date(anio, 1, 1)
    self.fecha_final = new Date(anio, 11, 30)

    // Actualización de la gráfica cuando se cambia el tamaño de la gráfica
    self.refrescarVentasComisiones()
  },
  mounted() {
    iu.spinner.mostrar('#graficaVentasComisiones')
  },
  methods: {
    dibujarGrafica(){
      var self = this
      self.series = []
      self.series = [
        {
          name: "Total",
          data: [
            self.ventas_y_comisiones.comisiones,
            self.ventas_y_comisiones.ventas_brutas,
            self.ventas_y_comisiones.ventas_netas
          ]
        }
      ]
      self.chartOptions = {
        chart: {
          height: 350,
          type: "bar"
        },
        colors: ["#3b76e1","#63ad6f","#57C9EB"],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [
            "Comisiones",
            "Ventas brutas",
            "Ventas netas"
          ],
          labels: {
            style: {
              fontSize: "13px"
            }
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return self.formatoMoneda(val)
            }
          }
        }
      }
    },
    formatoFechaVentas(mt){
      return moment(mt, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },
    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },
    refrescarVentasComisiones: function() {
      var self = this

      iu.spinner.mostrar('#graficaVentasComisiones')

      let params = {
        fecha_inicial : self.formatoFechaVentas(self.fecha_inicial),
        fecha_final : self.formatoFechaVentas(self.fecha_final),
      }

      VendedorSrv.ventasYComisionesJSON(params).then(response => {
        Object.assign(self.ventas_y_comisiones,response.data)
        self.dibujarGrafica()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo obtener las ventas y comisiones'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#graficaVentasComisiones')
      })
    }
  }
}
</script>
<style scoped></style>