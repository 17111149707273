<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      Ingresos
      <div class="bx-pull-right">
        <div class="btn-group mb-1 me-2">
          <button
            class="btn btn-sm"
            :class="{
              'btn-primary': tipo_ventas == 'brutas',
              'btn-outline-primary': tipo_ventas != 'brutas'
            }"
            @click="
              tipo_ventas = 'brutas',
              refrescarVentas()
            "
          >
            Brutas
          </button>
          <button
            class="btn btn-sm"
            :class="{
              'btn-primary': tipo_ventas == 'netas',
              'btn-outline-primary': tipo_ventas != 'netas'
            }"
            @click="
              tipo_ventas = 'netas',
              refrescarVentas()
            "
          >
            Netas
          </button>
        </div>
        <div class="btn-group mb-1">
          <button
            class="btn btn-sm"
            :class="{
              'btn-primary': tipo_reporte == 'diario',
              'btn-outline-primary': tipo_reporte != 'diario'
            }"
            @click="
              tipo_reporte = 'diario',
              refrescarVentas()
            "
          >
            Día
          </button>
          <button
            class="btn btn-sm"
            :class="{
              'btn-primary': tipo_reporte == 'semanal',
              'btn-outline-primary': tipo_reporte != 'semanal'
            }"
            @click="
              tipo_reporte = 'semanal',
              refrescarVentas()
            "
          >
            Semana
          </button>
          <button
            class="btn btn-sm"
            :class="{
              'btn-primary': tipo_reporte == 'mensual',
              'btn-outline-primary': tipo_reporte != 'mensual'
            }"
            @click="
              tipo_reporte = 'mensual',
              refrescarVentas()
            "
          >
            Mes
          </button>
          <button
            class="btn btn-sm"
            :class="{
              'btn-primary': tipo_reporte == 'anual',
              'btn-outline-primary': tipo_reporte != 'anual'
            }"
            @click="
              tipo_reporte = 'anual',
              refrescarVentas()
            "
          >
            Año
          </button>
        </div>
      </div>
    </h5>
    <div class="card-body pb-0">
      <div class="row">
        <div class="col-md-5">
          <div class="row mb-4">
            <label class="col-sm-4 col-4 col-form-label text-truncate">Fecha inicial</label>
            <div class="col-sm-8 col-8">
              <Datepicker
                ref="fecha_inicial"
                v-model="fecha_inicial"
                :class="'form-control bg-white'"
                :inputFormat="'dd MMMM yyyy'"
              />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="row mb-4">
            <label class="col-sm-4 col-4 col-form-label text-truncate">Fecha final</label>
            <div class="col-sm-8 col-8">
              <Datepicker
                ref="fecha_final"
                v-model="fecha_final"
                :class="'form-control bg-white'"
                :inputFormat="'dd MMMM yyyy'"
              />
            </div>
          </div>
        </div>
        <div class="col-md-2 col-5 text-right">
          <button class="btn btn-outline-success btn-block text-truncate w-100" @click="refrescarVentas()">
            <i class="mdi mdi-refresh"></i>
            Refrescar
          </button>
        </div>
      </div>
      <br>
      <div id="graficaIngresos">
        <p class="text-muted mb-1 text-justify">
          Esta gráfica te ayuda a ver los ingresos optenidos de las fichas de hotspot a lo largo del tiempo.
        </p>
        <apexchart
          class="apex-charts"
          height="450"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import FichasSrv from '@/services/hotspot/FichasSrv.js'
import Datepicker from 'vue3-datepicker'
import accounting from 'accounting/accounting.js'
import moment from 'moment'

export default {
  name: 'WIngresos',
  components: {
    Datepicker
  },
  data() {
    return {
      ventas: [],
      label: [],
      datos: [],

      fecha_inicial : '',
      fecha_final : '',
      tipo_reporte : 'diario',
      tipo_ventas: 'brutas',
      
      meses: [
        { mes:'Ene', numeroMes:1 }, { mes:'Feb', numeroMes:2 },
        { mes:'Mar', numeroMes:3 }, { mes:'Abr', numeroMes:4 },
        { mes:'May', numeroMes:5 }, { mes:'Jun', numeroMes:6 },
        { mes:'Jul', numeroMes:7 }, { mes:'Ago', numeroMes:8 },
        { mes:'Sep', numeroMes:9 }, { mes:'Oct', numeroMes:10 },
        { mes:'Nov', numeroMes:11 }, { mes:'Dic', numeroMes:12 }
      ],
      
      series: [
        {
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 400,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#63ad6f"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: [
            // "Ene",
            // "Feb",
            // "Mar",
            // "Apr",
            // "May",
            // "Jun",
            // "Jul",
            // "Aug",
            // "Sep",
            // "Oct",
            // "Nov",
            // "Dic"
          ],
        },
      },
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created() {
    var self = this

    let hoy = new Date()
    var anio = hoy.getFullYear();
    self.fecha_inicial = new Date(anio, 1, 1)
    self.fecha_final = new Date(anio, 11, 30)
    
    // Actualización de la gráfica cuando se cambia el tamaño de la gráfica
    self.refrescarVentas()

    // Actualización de la gráfica cuando se cambia el tamaño de la gráfica
    window.addEventListener('resize', () => {
      self.dibujarGrafica(self.ventas)
    })
  },
  methods: {
    dibujarGrafica(ventas){
      var self = this

      let categories = []
      self.series[0].data = []

      if(self.tipo_reporte == 'diario') {
        ventas.forEach(venta => {
          categories.push(moment(venta.fecha_venta).format('DD/MM/YYYY'))
          self.series[0].data.push(venta.total)
        })
      }

      if(self.tipo_reporte == 'semanal'){
        ventas.forEach(venta => {
          categories.push(venta.semana_venta)
          self.series[0].data.push(venta.total)
        })
      }

      if(self.tipo_reporte == 'anual') {
        ventas.forEach(venta => {
          categories.push(venta.anio_venta)
          self.series[0].data.push(venta.total)
        })
      }

      if(self.tipo_reporte == 'mensual'){
        ventas.forEach(venta => {
          var mes = self.meses.find(mes => mes['numeroMes'] == venta.mes_venta)
          categories.push(mes==undefined?'Mes':mes['mes'])
          self.series[0].data.push(venta.total)
        })
      }

      self.series[0].name="Total"

      self.chartOptions = {
        chart: {
          height: 400,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#63ad6f"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: categories,
          labels: {
            style: {
              fontSize: "13px",
            }
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return self.formatoMoneda(val)
            }
          }
        }
      }
    },
    formatoFechaVentas(mt){
      return moment(mt, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },

    refrescarVentas: function() {
      var self = this

      iu.spinner.mostrar('#graficaIngresos')

      self.ventas = []

      let params = {
        fecha_inicial : self.formatoFechaVentas(self.fecha_inicial),
        fecha_final : self.formatoFechaVentas(self.fecha_final),
        tipo_reporte : self.tipo_reporte,
        tipo_ventas: self.tipo_ventas
      }

      FichasSrv.ventasJSON(params).then(response => {
        self.ventas = response.data
        self.dibujarGrafica(self.ventas)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo obtener los ingresos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#graficaIngresos')
      })
    }
  },
  mounted: function(){
    iu.spinner.mostrar('#graficaIngresos')
  },
}
</script>