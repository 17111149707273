import axios from 'axios'
import { APIHOTS as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/portales-cautivos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(portal) {
    return apiClient.patch(`/${portal.id}`, portal)
  },

  actualizarLogo(idPortal, logo) {
    let formData = new FormData(),
      headers = { headers: { 'Content-Type': 'multipart/form-data' } }
    formData.append('logo', logo)

    return apiClient.post(`${idPortal}/logo`, formData, headers)
  },

  cantidad() {
    return apiClient.get('/cantidad')
  },

  eliminar(idPortal) {
    return apiClient.delete(`/${idPortal}`)
  },

  eliminarLogo(idPortal) {
    return apiClient.delete(`${idPortal}/logo`)
  },

  eliminarImagen(idPortal,nombre){
    return apiClient.delete(`${idPortal}/imagenes/${nombre}`)
  },

  guardar(portal) {
    return apiClient.post('', portal)
  },

  guardarImagen(idPortal, imagen, nombre) {
    let formData = new FormData(),
        headers = { headers: { 'Content-Type' : 'multipart/form-data' } }
    
    formData.append('imagen', imagen)

    return apiClient.post(`${idPortal}/imagenes/${nombre}`,formData, headers)
  },

  portalesJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/portales-cautivos.json`, { params: params })
  },

  portalJSON(idPortal,params) {
    params = params || {}
    return apiClient.get(`${idPortal}.json`,{params:params})
  }
}