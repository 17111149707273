<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      <span class="badge bg-primary rounded-pill bx-pull-right font-size-12">
        Cantidades
      </span>
      Fichas
    </h5>
    <div class="card-body">
      <div class="row">
        <div class="col-6 text-center">
          <router-link :to="{ name: 'fichasHotspot', query: { estado: 2 } }">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-primary bg-gradient font-size-20" :title="cantidad_fichas_activas">
                {{ cantidad_fichas_activas <= 9999 ? cantidad_fichas_activas : '999+' }}
              </div>
            </div>
            <div class="text-primary text-center text-truncate w-100">
              Fichas activas
            </div>
          </router-link>
        </div>
        <div class="col-6 text-center">
          <router-link :to="{ name: 'fichasHotspot', query: { estado:3 } }">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-warning bg-gradient font-size-20" :title="cantidad_fichas_pausadas">
                {{ cantidad_fichas_pausadas <= 9999 ? cantidad_fichas_pausadas : '999+' }}
              </div>
            </div>
            <div class="text-warning text-center text-truncate w-100">
              Fichas en pausa
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SesionesSrv from '@/services/hotspot/SesionesSrv.js'
import FichasSrv from '@/services/hotspot/FichasSrv.js'
import axios from 'axios'

export default {
  name: 'WFichas',

  data() {
    return {
      cantidad_fichas_activas: 0,
      cantidad_fichas_pausadas: 0,
      intervals: {
        actualizacion: null
      }
    }
  },

  created() {
    var self = this

    this.actualizar()
    this.intervals.actualizacion = setInterval(function() {
      self.actualizar()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },

  methods: {
    actualizar() {
      var self = this

      const prom1 = SesionesSrv.cantidadActivas()
      const prom2 = FichasSrv.cantidadPausadas()

      axios.all([prom1, prom2]).then(response => {
        self.cantidad_fichas_activas = response[0].data
        self.cantidad_fichas_pausadas = response[1].data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo obtener la cantidad de fichas creadas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
}
</script>