<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      <span class="badge bg-primary rounded-pill bx-pull-right font-size-12">
        Cantidades
      </span>
      Layouts
    </h5>
    <div class="card-body">
      <div class="row">
        <div class="col-6 text-center">
          <router-link :to="{ name: 'portalCautivoHotspot' }">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-success bg-gradient font-size-20" :title="cantidad_layouts_portales">
                {{ cantidad_layouts_portales <= 9999 ? cantidad_layouts_portales : '999+'}}
              </div>
            </div>
            <div class="text-success text-center text-truncate w-100">
              Para portales
            </div>
          </router-link>
        </div>
        <div class="col-6 text-center">
          <router-link :to="{ name: 'fichasLayoutHotspot' }">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-info bg-gradient font-size-20" :title="cantidad_layouts_fichas">
                {{ cantidad_layouts_fichas <= 9999 ? cantidad_layouts_fichas : '999+' }}
              </div>
            </div>
            <div class="text-info text-center text-truncate w-100">
              Para fichas
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FichasLayoutSrv from '@/services/hotspot/FichasLayoutSrv'
import PortalCautivoSrv from '@/services/hotspot/PortalCautivoSrv'
import axios from 'axios'
export default {
  name: 'WLayouts',

  data() {
    return {
      cantidad_layouts_portales: 0,
      cantidad_layouts_fichas: 0,
      intervals: {
        actualizacion: null
      }
    }
  },

  created() {
    var self = this

    this.actualizar()
    this.intervals.actualizacion = setInterval(function() {
      self.actualizar()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    actualizar() {
      var self = this

      const prom1 = FichasLayoutSrv.cantidad()
      const prom2 = PortalCautivoSrv.cantidad()

      axios.all([prom1,prom2]).then(response => {
        self.cantidad_layouts_fichas = response[0].data
        self.cantidad_layouts_portales = response[1].data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo obtener la cantidad de fichas vendidas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>